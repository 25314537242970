export const newYearHolidays = {
  start: '2023-12-27',
  end: '2024-01-06',
  period: 11
}

export const newYearHolidayAttention = {
  start: '2023-12-15',
  end: '2024-01-06'
}
