import { SectionTypeCode } from '@lifedot/constants/searchChoices'

export const isSectionTypeNormalOnly = (
  sectionTypes:
    | {
        code: string
      }[]
    | undefined
): boolean => {
  if (!sectionTypes) return false
  return (
    sectionTypes.length === 1 &&
    sectionTypes[0]?.code === SectionTypeCode.NORMAL
  )
}
